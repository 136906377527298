<template>
  <div class="device-mockup">
    <img
      v-lazy="{
        src: require('@/assets/images/simple-mac.webp'),
        loading: require('@/assets/images/simple-mac-lqip.webp'),
      }"
      alt="MacBook Mockup displaying the video below"
      class="device-image"
      loading="lazy"
    />
    <object class="screen-content">
      <video-content :video="video" :thumbnail="thumbnail" />
    </object>
  </div>
</template>

<script>
import VideoContent from './VideoContent.vue';
export default {
  components: {
    VideoContent,
  },
  props: ['video', 'thumbnail'],
};
</script>

<style scoped>
.device-mockup {
  position: relative;
  width: 100%;
  height: auto;
}

.device-image {
  width: 100%;
  display: block;
}

.screen-content {
  position: absolute;
  top: 4.4%;
  left: 11.5%;
  width: 77%;
  height: 85.5%;
}

/* .screen-content img, */
/* .screen-content video { */
/* object-fit: contain; */
/* } */
</style>
